import React, { Fragment, useState, useEffect } from 'react'

import { memoisedGroupedGeneralExpenses, memoisedGroupedWorkplaceExpenses } from '../../modules/expense'

import { timelineDateRange } from '../../modules/timelines'

import ButtonGroupItem from '../interactive/ButtonGroupItem'
import FactsItem from '../display/FactsItem'
import FlashMessage from '../display/FlashMessage'
import HeadingDivider from '../display/HeadingDivider'
import Helper from '../display/Helper'
import Lead from '../display/Lead'
import Block from '../display/Block'
import BlockHeader from '../display/BlockHeader'
import BlockBody from '../display/BlockBody'
import TitledDescriptionList from '../display/TitledDescriptionList'
import DescriptionList from '../display/DescriptionList'
import { List } from 'react-content-loader'

import {
  ButtonGroup,
  ContentFooter,
  ContentWrapper,
  ContentWrapperInner,
  Facts,
  Grid,
  GridInner
} from '../layout/Wrapper'

import { fetchReviewPageState, createSubmission } from '../../modules/api'
import { useViewport } from '../../modules/hooks'

import { BREAKPOINTS, flatten, redirectTo } from '../../modules/utils'

const DEFAULT_RESOURCES = {
  bankDetails: [],
  userDetails: [],
  reviewData: {
    employers: [],
    finance: {
      yesValues: [],
      noValues: []
    },
    workplaces: [],
    expenses: [],
    addresses: [],
    vehicles: []
  }
}

function DashboardBlock({ editPath, title, children }) {
  return (
    <Block id={`${title.toLowerCase()}-block`}>
      <BlockHeader actionHref={editPath} actionLabel="Edit">
        {title}
      </BlockHeader>
      <BlockBody modifiers={['padded']}>{children}</BlockBody>
    </Block>
  )
}

function DashboardFinanceFacts({ finance }) {
  return (
    <Facts modifiers="emoji facts--computer">
      {finance.yesValues.length > 0 && (
        <div className="finances-yes">
          {finance.yesValues.map((value, index) => (
            <FactsItem
              key={`facts__item--info-${index}`}
              title={value.label}
              text={value.microcopy}
              modifiers={['info']}
            />
          ))}
        </div>
      )}
      {finance.noValues.length > 0 && finance.yesValues.length > 0 && <hr className="ml-6 mr-6" />}
      {finance.noValues.length > 0 && (
        <div className="finances-no">
          {finance.noValues.map((value, index) => (
            <FactsItem key={`facts__item-${index}`} title={value.label} text={value.microcopy} />
          ))}
        </div>
      )}
    </Facts>
  )
}

function DashboardMissingInfoMessage({ title, body, linkText, linkHref }) {
  return (
    <Helper
      title={title}
      body={body}
      closeable={false}
      modifiers={['warning', 'visible']}
      additionalInnerClasses={['mt-0', 'mb-4']}
      helperLink={{
        text: linkText,
        href: linkHref
      }}
      iconName="grimace"
    />
  )
}

export default function ReviewForm({}) {
  const { width } = useViewport()

  const [twoCol, setTwoCol] = useState(width >= BREAKPOINTS.LARGE)
  const [, setSmallScreen] = useState(width < BREAKPOINTS.SMALL)
  const [error] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [resources, setResources] = useState(DEFAULT_RESOURCES)

  const { bankDetails, dataFetched, userDetails, reviewData, submitText, submittable } = resources
  const { workplaces, employers, expenses, addresses, finance, vehicles } = reviewData

  // Group these sensibly:
  const groupedWorkplaceExpenses = memoisedGroupedWorkplaceExpenses(expenses)
  const groupedGeneralExpenses = memoisedGroupedGeneralExpenses(expenses)

  const timelineTitle = `${timelineDateRange()[0].format('MMMM YYYY')} - Now`

  const rawWorkplaces = flatten(workplaces.map(w => w.data))
  const invalidWorkplaces = !!rawWorkplaces.filter(w => w.class_name === 'Api::Person::Location' && !w.valid).length

  useEffect(() => {
    setInitialState()
  }, [])
  useEffect(() => {
    setTwoCol(width >= BREAKPOINTS.LARGE)
    setSmallScreen(width < BREAKPOINTS.SMALL)
  }, [width])

  const setInitialState = async () => {
    const resources = await fetchReviewPageState()
    setResources(resources)
  }

  const handleSubmit = e => {
    e.preventDefault()
    setSubmitting(true)
    createSubmission()
      .then(() => redirectTo('/my_rift/dashboard'))
      .catch(() => setSubmitting(false))
  }

  const renderForm = () => {
    return (
      <>
        {!twoCol && (
          <DashboardBlock title="Finances" editPath="/finance/1/edit">
            <DashboardFinanceFacts finance={finance} />
          </DashboardBlock>
        )}

        {employers.length ? (
          <DashboardBlock title="Employments" editPath="/my_rift/employments">
            <TitledDescriptionList title={timelineTitle} list={employers.map(e => e.display_data)} titleEl="h4" />
          </DashboardBlock>
        ) : (
          <DashboardMissingInfoMessage
            title="You haven't added any employers yet"
            body="Before you submit you'll need to add at least one employer."
            linkText="Add an employment"
            linkHref="/employments/new"
          />
        )}

        {rawWorkplaces.length ? (
          <Fragment>
            <DashboardBlock title="Workplaces" editPath="/my_rift/work_locations">
              {invalidWorkplaces && (
                <DashboardMissingInfoMessage
                  title="Some of your workplaces are missing information"
                  body="Before you submit you'll need to update these workplaces."
                  linkText="Review workplaces"
                  linkHref="/my_rift/timelines/work_locations"
                />
              )}
              {workplaces.map(({ heading, data }, index) => {
                const list = data.map(e => e.display_data)
                if (list.length) {
                  return <TitledDescriptionList key={index} title={heading} list={list} titleEl="h4" />
                }
              })}
            </DashboardBlock>
          </Fragment>
        ) : (
          <DashboardMissingInfoMessage
            title="You haven't added any workplaces yet"
            body="Before you submit you'll need to add at least one workplace."
            linkText="Add a workplace"
            linkHref="/work_locations/new"
          />
        )}

        {!!vehicles.length && (
          <DashboardBlock title="Vehicles" editPath="/my_rift/vehicles">
            <TitledDescriptionList title={timelineTitle} list={vehicles.map(e => e.display_data)} titleEl="h4" />
          </DashboardBlock>
        )}

        {!!expenses.length && (
          <DashboardBlock title="Expenses" editPath="/my_rift/expenses">
            {!!Object.keys(groupedWorkplaceExpenses).length && (
              <Fragment>
                <h3 className="block__text-heading">Workplace expenses</h3>
                {Object.keys(groupedWorkplaceExpenses).map((title, index) => (
                  <TitledDescriptionList
                    key={index}
                    list={groupedWorkplaceExpenses[title]}
                    title={title}
                    titleEl="h4"
                  />
                ))}
              </Fragment>
            )}
            {!!Object.keys(groupedGeneralExpenses).length && (
              <Fragment>
                <h3 className="block__text-heading">General expenses</h3>
                {Object.keys(groupedGeneralExpenses).map((title, index) => (
                  <TitledDescriptionList key={index} list={groupedGeneralExpenses[title]} title={title} titleEl="h4" />
                ))}
              </Fragment>
            )}
          </DashboardBlock>
        )}

        {addresses.length ? (
          <DashboardBlock title="Addresses" editPath="/my_rift/addresses">
            <TitledDescriptionList title={timelineTitle} list={addresses.map(e => e.display_data)} titleEl="h4" />
          </DashboardBlock>
        ) : (
          <DashboardMissingInfoMessage
            title="You haven't added any addresses yet"
            body="Before you submit you'll need to add at least one addresses."
            linkText="Add an address"
            linkHref="/addresses/new"
          />
        )}

        <DashboardBlock title="Customer Details" editPath="/users/1/edit">
          <DescriptionList list={userDetails} />
        </DashboardBlock>

        <DashboardBlock title="Bank Details" editPath="/preferences">
          <DescriptionList list={bankDetails} />
        </DashboardBlock>
      </>
    )
  }

  return (
    <React.Fragment>
      {error && <FlashMessage type="error" body={error} />}

      <Grid modifiers={['6-5', 'align-start']}>
        {twoCol && (
          <GridInner>
            <ContentWrapper className="overflow-hidden">
              <HeadingDivider>Finances</HeadingDivider>
              <ContentWrapperInner>
                <DashboardFinanceFacts finance={finance} />
              </ContentWrapperInner>
              <ContentFooter modifiers="actions">
                <ButtonGroup modifiers="left">
                  <ButtonGroupItem buttonModifiers="default" element="anchor" href="/finance/1/edit">
                    Update my answers
                  </ButtonGroupItem>
                </ButtonGroup>
              </ContentFooter>
            </ContentWrapper>
          </GridInner>
        )}

        <GridInner>
          <ContentWrapper>
            <ContentWrapperInner modifiers="longform">
              <Lead>
                Nearly done! Check that everything you've added is correct, and then{' '}
                <strong>submit your claim for review.</strong>
              </Lead>
              <hr />
              {dataFetched ? (
                renderForm()
              ) : (
                <>
                  <List /> <List /> <List />
                </>
              )}
            </ContentWrapperInner>
            <ContentFooter modifiers="actions">
              <ButtonGroup modifiers="spread">
                <ButtonGroupItem buttonModifiers="default" element="anchor" href="/">
                  Cancel
                </ButtonGroupItem>
                <ButtonGroupItem
                  buttonModifiers={submitting ? ['primary', 'loading'] : 'primary'}
                  element="anchor"
                  href="#"
                  onClick={handleSubmit}
                  disabled={!submittable || submitting}
                >
                  {submitText}
                </ButtonGroupItem>
              </ButtonGroup>
            </ContentFooter>
          </ContentWrapper>
        </GridInner>
      </Grid>
    </React.Fragment>
  )
}
